<template>
  <div class="cont">
    首页
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
